import { Alert, Card, Col, Form, Row, UncontrolledTooltip } from "reactstrap";
import {
  Button,
  Icon,
  PreviewCard,
  TooltipComponent,
} from "../../../components/Component";
import Input from "../../../components/input/input/input";
import Select from "../../../components/input/select/select";
import useForm from "../../../hooks/use-form";
import { ProductOptionTypes } from "../../../utils/statics";



export const CouponTypes = {
  DISCOUNT: 'discount',
  GIFT: 'gift',
};

const CouponCard = ({
  validationErrors,
  setIsCouponUpdated,
  index,
  inputs,
  setOne,
  setFields,
}) => {
  const OnRemoveValue = (id) => {
    setIsCouponUpdated(true);
    setFields((prev) => {
      let newObj = { ...prev };
      newObj.coupons = newObj.coupons.map((coupon) => {
        if (coupon.id !== inputs.coupons[index].id) {
          return coupon;
        } else {
          return {
            ...coupon,
            values: coupon.values.filter((val) => val.id !== id),
          };
        }
      });
      return newObj;
    });
  };

  const onCouponValueAdd = () => {
    setFields((prev) => {
      let newObj = { ...prev };
      newObj.coupons = newObj.coupons.map((coupon) => {
        if (coupon.id !== inputs.coupons[index].id) {
          return coupon;
        } else {
          return {
            ...coupon,
            values: [
              ...coupon.values,
              {
                id: Date.now(), // Use a more reliable unique ID generator
                value: "",
                color: "#000000",
                discount: "", // Ensure this matches your state
              },
            ],
          };
        }
      });
      return newObj;
    });
    setIsCouponUpdated(true);
  };

  return (
    <Col sm={12}>
      <PreviewCard>
        <button
          type="button"
          className="close"
          onClick={() => {
            setFields((prev) => {
              let newObj = { ...prev };
              newObj.coupons = newObj.coupons.filter(
                (coupon) => coupon.id !== inputs.coupons[index].id
              );
              return newObj;
            });
            setIsCouponUpdated(true);
          }}
        >
          <Icon name="cross" />
        </button>
        <Row className="g-4">
          <Input
            columnConfig={{ sm: 6 }}
            label="Code"
            set={setOne}
            name={`coupons[${index}].code`}
            model={inputs.coupons[index].code}
            error={validationErrors?.[`coupons.${index}.code`]}
          />
          <Select
            options={[
              { label: "Discount", value: CouponTypes.DISCOUNT },
              { label: "Gift", value: CouponTypes.GIFT },
            ]}
            columnConfig={{ sm: 6 }}
            label="Type"
            set={setOne}
            name={`coupons[${index}].type`}
            model={inputs.coupons[index].type}
            error={validationErrors?.[`coupons.${index}.type`]}
          />

          <Col sm={12}>
            <table className="table table-ulogs">
              <thead className="thead-light">
                <tr>
                  <th className="tb-col-os" style={{ verticalAlign: "middle" }}>
                    <span className="overline-title">Value</span>
                  </th>
                  {inputs.coupons[index]?.type === CouponTypes.DISCOUNT && (
                    <th className="tb-col-ip" style={{ verticalAlign: "middle" }}>
                      <span className="overline-title">Discount Amount</span>
                    </th>
                  )}
                  <th className="tb-col-action" style={{ verticalAlign: "middle" }}>
                    <span className="overline-title">
                      <Button
                        onClick={onCouponValueAdd}
                        size="sm"
                        className="m-auto btn-icon btn-round"
                        type="button"
                        color="outline-light"
                      >
                        <Icon name="plus" />
                      </Button>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {inputs.coupons[index].values?.map((item, idx) => (
                  <tr key={item.id}>
                    <td className="tb-col-ip" style={{ paddingLeft: 0 }}>
                      <Input
                        set={setOne}
                        placeholder="Type the value"
                        name={`coupons[${index}].values[${idx}].value`}
                        model={inputs.coupons[index].values[idx].value}
                        error={validationErrors?.[`coupons.${index}.values.${idx}.value`]}
                      />
                    </td>
                    {inputs.coupons[index].type === CouponTypes.DISCOUNT && (
                      <td className="tb-col-ip">
                        <Input
                          type="number"
                          set={setOne}
                          name={`coupons[${index}].values[${idx}].discount`}
                          model={inputs.coupons[index].values[idx].discount}
                          error={validationErrors?.[`coupons.${index}.values.${idx}.discount`]}
                        />
                      </td>
                    )}
                    <td className="tb-col-action align-middle">
                      <a
                        href="#delete"
                        onClick={() => OnRemoveValue(item.id)}
                        className="link-cross mr-sm-n1"
                      >
                        <Icon name="cross" />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {inputs.coupons[index].values?.length === 0 && !validationErrors?.[`coupons.${index}.values`] && (
              <div className="">
                <Alert color="light" className="alert-icon text-center">
                  No values added so far :(
                </Alert>
              </div>
            )}
            {validationErrors?.[`coupons.${index}.values`] && inputs.coupons[index].values?.length === 0 && (
              <div className="">
                <Alert color="danger" className="alert-icon text-center">
                  {validationErrors?.[`coupons.${index}.values`]}
                </Alert>
              </div>
            )}
          </Col>
        </Row>
      </PreviewCard>
    </Col>
  );
};

export default CouponCard;


