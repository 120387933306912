import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Step, Steps } from "react-step-builder";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import HeadSection from "../../components/admin-page-components/HeadSection";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  PreviewCard
} from "../../components/Component";
import Overlay from "../../components/loaders/page-overlay";
import useForm from "../../hooks/use-form";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import apiRoutes from "../../routes/api-routes";
import routes from "../../routes/routes-data";
import { VariantTypes } from "../../utils/statics";
import Details from "./create-update-partials/details";
import Options from "./create-update-partials/options";
import PriceList from "./create-update-partials/price-list";
// let options = [{
//  id : ""
//   name: "",
//   type: "",
//   values: [
//     {
//       value: "",
//       color:""
//     }
//   ]
// }];
const ProductCreateUpdate = (props) => {
  const { inputs, setOne, setFields, reset } = useForm({
    images: [],
    existing_images: [],
    name: "",
    is_featured: false,
    description: "",
    category_id: "",
    website: "",
    carriers: [],
    size_guide_id: "",
    options: [],
    variants: [],
    custom_variants: []
  });

  const { isLoading: InitialLoading, sendRequest: sendInititalRequest } =
    useRequest();

  let location = useLocation();
  let { productId } = useParams();
  const [isOptionUpdated, setIsOptionUpdated] = useState(false);

  const onUnmount = (fields) => {
    setFields((prev) => ({ ...prev, ...fields }));
  };

  const getProduct = useCallback(async () => {
    let response = await sendInititalRequest({
      url: apiRoutes.PRODUCT_SHOW(productId)
    });

    if (response) {
      let existing_images = response.images?.map((image) => ({
        file: null,
        url: image.path,
        id: image.id,
        is_existing: true
      }));

      let carriers = response.carriers?.map((carrier) => ({
        label: carrier.name,
        value: carrier.id
      }));

      let options = response.product_options?.map((option) => ({
        id: option.id,
        is_existing: true,
        name: option.name,
        type: option.type,
        values:
          option.product_option_values?.map((val) => ({
            id: val.id,
            is_existing: true,
            value: val.value,
            color: val.color
          })) ?? []
      }));

      let variants = response.product_variants
        ?.filter((variant) => variant.type == VariantTypes.STANDARD_VARIANT)
        .map((variant, index) => {
          let imageIndex = response.images.findIndex(
            (img) => img.id == variant.image_id
          );
          return {
            option_values:
              variant.product_option_values?.map((optVal) => [
                optVal.value,
                optVal.color,
                optVal.id
              ]) ?? null,
            current_price: variant.current_price,
            standard_price: variant.standard_price,
            unit_weight: variant.unit_weight,
            stock: variant.stock,
            id: variant.id,
            is_existing: true,
            image_index: response.images
              ? imageIndex != -1
                ? imageIndex + 1
                : null
              : null
          };
        });

      let custom_variants = response.product_variants
        ?.filter((variant) => variant.type == VariantTypes.CUSTOM_VARIANT)
        ?.map((variant, index) => {
          let imageIndex = response.images.findIndex(
            (img) => img.id == variant.image_id
          );
          return {
            option_values:
              variant.product_option_values?.map((optVal) => [
                optVal.value,
                optVal.color,
                optVal.id
              ]) ?? null,
            current_price: variant.current_price,
            standard_price: variant.standard_price,
            unit_weight: variant.unit_weight,
            id: variant.id,
            is_existing: true,
            image_index: response.images
              ? imageIndex != -1
                ? imageIndex + 1
                : null
              : null
          };
        });

      setFields({
        images: existing_images ?? [],
        name: response.name,
        is_featured: response.is_featured == 1,
        description: response.description,
        category_id: {
          value: response.category?.id,
          label: response.category?.name
        },
        website: response.website,
        carriers: carriers ?? [],
        size_guide_id: response.size_guide_id ?? "",
        options: options ?? [],
        variants: variants ?? [],
        custom_variants: custom_variants ?? []
      });
    }
  }, [productId, sendInititalRequest, setFields]);

  useEffect(() => {
    setFields({
      images: [],
      existing_images: [],
      name: "",
      is_featured: false,
      description: "",
      category_id: "",
      website: "",
      carriers: [],
      size_guide_id: "",
      options: [],
      variants: [],
      custom_variants: []
    });
  }, [location.pathname, setFields]);

  useEffect(() => {
    if (productId) {
      getProduct();
    }
  }, [getProduct, productId]);
  return (
    <Content>
      <HeadSection
        title={`${productId ? "Edit" : "Create"} Product`}
        right={
          <Breadcrumb className="breadcrumb-arrow">
            <BreadcrumbItem>
              <Link to={routes.PRODUCT_INDEX}>Products</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {productId ? "Edit" : "create"}
            </BreadcrumbItem>
          </Breadcrumb>
        }
      />

      {InitialLoading && (
        <PreviewCard>
          <Overlay spinner={true} bg />
        </PreviewCard>
      )}

      {!InitialLoading && (
        <PreviewCard>
          <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
            <Steps
              config={{
                navigation: {
                  component: (props) => {
                    return (
                      <div className="steps clearfix">
                        <ul>
                          <li
                            className={
                              props.current >= 1 ? "first done" : "first"
                            }
                          >
                            <a
                              href="#wizard-01-h-0"
                              onClick={(ev) => ev.preventDefault()}
                              style={{ cursor: "default" }}
                            >
                              <span className="number">01</span>{" "}
                              <h5>Details</h5>
                            </a>
                          </li>
                          <li className={props.current >= 2 ? "done" : ""}>
                            <a
                              href="#wizard-01-h-1"
                              onClick={(ev) => ev.preventDefault()}
                              style={{ cursor: "default" }}
                            >
                              <span className="number">02</span>{" "}
                              <h5>Options</h5>
                            </a>
                          </li>
                          <li className={props.current >= 3 ? "done" : ""}>
                            <a
                              href="#wizard-01-h-1"
                              onClick={(ev) => ev.preventDefault()}
                              style={{ cursor: "default" }}
                            >
                              <span className="number">03</span>{" "}
                              <h5>Price List</h5>
                            </a>
                          </li>
                        </ul>
                      </div>
                    );
                  },
                  location: "before"
                }
              }}
            >
              <Step
                title="Details"
                component={(props) => (
                  <Details
                    stepState={props}
                    fields={inputs}
                    onUnmount={onUnmount}
                    productId={productId}
                  />
                )}
              />

              <Step
                title="Options"
                component={(props) => (
                  <Options
                    stepState={props}
                    fields={inputs}
                    onUnmount={onUnmount}
                    productId={productId}
                    isOptionUpdated={isOptionUpdated}
                    setIsOptionUpdated={setIsOptionUpdated}
                  />
                )}
              />

              <Step
                title="Price List"
                component={(props) => (
                  <PriceList
                    stepState={props}
                    fields={inputs}
                    onUnmount={onUnmount}
                    isOptionUpdated={isOptionUpdated}
                    setIsOptionUpdated={setIsOptionUpdated}
                    productId={productId}
                  />
                )}
              />
            </Steps>
          </div>
        </PreviewCard>
      )}
    </Content>
  );
};

export default ProductCreateUpdate;
