import { Navigate, Route, Routes as ReactRoutes } from "react-router-dom";
import AuthLayout from "../layouts/auth";
import Layout from "../layouts/dashboard/Index";
import Login from "../pages/account/login";
import Register from "../pages/account/register";
import AdminsCreateEdit from "../pages/admins/create-edit";
import AdminsIndex from "../pages/admins/admins-index";
import Error404 from "../pages/error/404";
import Error from "../pages/error/index";
import Authenticated from "../security/authenticated";
import NoAdmins from "../security/no-admins";
import SuperAdmin from "../security/super-admin";
import CarriersIndex from "../pages/carriers/carriers-index";
import ShippingCountriesIndex from "../pages/carriers/shipping-countries/shipping-countries-index";
import CategoriesIndex from "../pages/categories/categories-index";

import Profile from "../pages/user-settings/profile";
import UserSettingsLayout from "../layouts/user-settings/user-settings-layout";

import Security from "../pages/user-settings/security";
import Activities from "../pages/user-settings/activities";
import ProductCreateUpdate from "../pages/products/create-update";
import Content from "../layouts/dashboard/content/Content";
import ProductsIndex from "../pages/products/products-index";
import SizeGuidesIndex from "../pages/size-guides/size-guides-index";
import BannersIndex from "../pages/banners/banners-index";
import OrdersIndex from "../pages/orders/orders-index";
import OrdersInvoice from "../pages/orders/orders-invoice";
import EventsIndex from "../pages/events/events-index";
import EventRegistrations from "../pages/events/events-registrations";
import CustomersIndex from "../pages/customers/customers-index";
import DirectMessagesIndex from "../pages/direct-messages/direct-messages-index";
import NewsletterSubscribersIndex from "../pages/newsletter-subscribers/newsletter-subscribers-index";

import CouponsIndex from "../pages/newsletter-subscribers/coupons";
import CouponCreateUpdate from "../pages/newsletter-subscribers/create-updates";

import AppSettingsLayout from "../layouts/app-settings/app-settings-layout";
import NotificationConfigurations from "../pages/app-settings/notification-configurations";

const Routes = (props) => {
  return (
    <ReactRoutes>
      {/************************************************ 
                      GUEST ROUTES
      *************************************************/}

      {/* -----------------------------
                AUTHENTICATION
      -------------------------------- */}
      <Route path="/account" element={<AuthLayout />}>
        <Route path="/account/login" element={<Login />} />

        <Route element={<NoAdmins />}>
          <Route path="/account/register" element={<Register />} />
        </Route>
      </Route>

      {/************************************************ 
                     AUTHENTICATE ROUTES
      *************************************************/}
      <Route path="/" element={<Authenticated />}>
        <Route path="/" element={<Layout />}>
          {/* -----------------------------
                        DASHBOARD
          -------------------------------- */}
          <Route path="/" element={<Navigate to="/orders" />} />

          {/* -----------------------------
                        ORDERS
          -------------------------------- */}
          <Route path="/orders" element={<OrdersIndex />} />
          <Route path="/orders/:orderId" element={<OrdersInvoice />} />

          {/* -----------------------------
                        CARRIER
          -------------------------------- */}
          <Route path="/carriers" element={<CarriersIndex />} />
          <Route
            path="/carriers/:carrierId/countries"
            element={<ShippingCountriesIndex />}
          />

          {/* -----------------------------
                        CATEGORY
          -------------------------------- */}
          <Route path="/categories" element={<CategoriesIndex />} />
          <Route path="/categories/:parentId" element={<CategoriesIndex />} />

          {/* -----------------------------
                        SIZE GUIDE
          -------------------------------- */}
          <Route path="/size-guides" element={<SizeGuidesIndex />} />

          {/* -----------------------------
                        BANNER
          -------------------------------- */}
          <Route path="/banners/:type" element={<BannersIndex />} />

          {/* -----------------------------
                        CUSTOMERS
          -------------------------------- */}
          <Route path="/customers" element={<CustomersIndex />} />

          {/* -----------------------------
                NEWSLETTER  SUBSCRIBERS
          -------------------------------- */}
          <Route
            path="/newsletter-subscribers"
            element={<NewsletterSubscribersIndex />}
          />

          {/* -----------------------------
              COUPONS
        -------------------------------- */}

          <Route path="/coupons" element={<CouponsIndex />} />
          <Route path="/create-updates" element={<CouponCreateUpdate />} />
          <Route
            path="/create-updates/:couponId"
            element={<CouponCreateUpdate />}
          />

          {/* -----------------------------
                        DIRECT MESSAGE
          -------------------------------- */}
          <Route path="/direct-messages" element={<DirectMessagesIndex />} />

          {/* -----------------------------
                        EVENTS
          -------------------------------- */}
          <Route path="/events" element={<EventsIndex />} />
          <Route path="/events/:eventId" element={<EventRegistrations />} />

          {/* -----------------------------
                        PRODUCT
          -------------------------------- */}
          <Route path="/products/create" element={<ProductCreateUpdate />} />
          <Route
            path="/products/:productId"
            element={<ProductCreateUpdate />}
          />
          <Route path="/products" element={<ProductsIndex />} />

          {/* -----------------------------
                        ACCOUNT SETTINGS
          -------------------------------- */}
          <Route path="/user-settings" element={<UserSettingsLayout />}>
            <Route path="profile" element={<Profile />} />
            <Route path="activities" element={<Activities />} />
            <Route path="security" element={<Security />} />
          </Route>

          {/* -----------------------------
                        APP SETTINGS
          -------------------------------- */}
          <Route path="/settings" element={<AppSettingsLayout />}>
            <Route
              path="notification-configurations"
              element={<NotificationConfigurations />}
            />
          </Route>

          {/************************************************ 
                     AUTHENTICATE & SUPER ADMIN ROUTES
          *************************************************/}
          <Route element={<SuperAdmin />}>
            {/* -----------------------------
                        ADMIN
            -------------------------------- */}
            <Route path="/admins" element={<AdminsIndex />} />
            <Route path="/admins/create" element={<AdminsCreateEdit />} />
            <Route path="/admins/:adminId" element={<AdminsCreateEdit />} />
          </Route>
        </Route>
      </Route>
      <Route path="/error" element={<Error />} />
      <Route path="*" element={<Error404 />} />
    </ReactRoutes>
  );
};

export default Routes;
