import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { Icon, TooltipComponent } from "../../components/Component";
import PageTable from "../../components/admin-page-components/PageTable";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import PriceRange from "../../components/html/PriceRange";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import {
  capitalizeText,
  imageKitLoader,
  searchParamsToObject,
} from "../../utils/methods";
import HeadSection from "../../components/admin-page-components/HeadSection";
import Button from "../../components/button/Button";
import Swal from "sweetalert2";
import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";
import routes from "../../routes/routes-data";
import { useSelector } from "react-redux";
import Image from "../../components/image/Image";





const CouponsIndex = (props) => {
  const [paginationData, setPaginationData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [coupons, setCoupons] = useState([]);
  const { isLoading, sendRequest, error } = useRequest();
  const { sendRequest: sendRequestFeatured } = useRequest(); // If applicable for coupons

  let location = useLocation();

  let websitesOption = useSelector(
    (store) => store.auth.accessibleWebsites
  ).map((web) => ({
    label: capitalizeText(web),
    value: web,
  }));

  const getCoupons = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.COUPON_INDEX, // Update endpoint for coupons
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
      },
    });
    if (response) {
      setCoupons(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page != 1 ? response : null);
    }
  }, [searchParams, sendRequest]);

  const onDeleteConfirm = async (id) => {
    if (!isLoading) {
      let response = await sendRequest({
        url: apiRoutes.COUPON_DELETE(id), // Update endpoint for coupons
        method: "DELETE",
      });
      if (response) {
        addAlert(toast.TYPE.SUCCESS, "Coupon has been deleted successfully");
        getCoupons();
      }
    }
    return true;
  };

  const onDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteConfirm(id);
      }
    });
  };

  const onFeaturedClick = async (id) => {
    setCoupons((prev) => {
      return prev?.map((coupon) => {
        if (coupon.id === id) {
          return {
            ...coupon,
            is_featured: !coupon.is_featured,
          };
        } else {
          return coupon;
        }
      });
    });
    await sendRequestFeatured({
      url: apiRoutes.COUPON_UPDATE_FEATURED(id), // Update endpoint for coupons
      method: "PUT",
    });
  };

  useEffect(() => {
    setCoupons([]);
  }, [location.pathname]);

  useEffect(() => {
    getCoupons();
  }, [getCoupons, searchParams]);

  return (
    <>
      <Head title="Coupons"></Head>
      <Content>
        <HeadSection
          title={"Coupons"}
          right={
            <Button
              to={routes.COUPON_CREATE} // Update route for coupon creation
              className="d-inline-flex"
              color="primary"
            >
              <Icon name="plus"></Icon>
              <span className="d-none d-md-block">Add Coupon</span>
            </Button>
          }
        />

        <PageTable
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          searchPlaceholder={"Search by coupon code or website"}
          title={"Main Coupons"}
          isLoading={isLoading}
          error={error}
          headers={[
            "code",
            "type",
            "value",
            "expiry_date",
            "website",
            <TooltipComponent
              direction={"top"}
              id="is_fav"
              icon={"star-fill"}
              text="Mark coupon as featured"
              iconClass={"tb-asterisk"}
            ></TooltipComponent>,
          ]}
          actions={true}
          body={coupons?.map((coupon) => [
            <span className="tb-product">
              <span className="title text-capitalize">{coupon.code}</span>
            </span>,
            coupon.type,
            coupon.value,
            new Date(coupon.expiry_date).toLocaleDateString(), // Format the date if necessary
            capitalizeText(coupon.website),
            <div className="asterisk tb-asterisk">
              <div
                className={coupon.is_featured ? "active" : ""}
                onClick={() => onFeaturedClick(coupon.id)}
              >
                <Icon name="star" className="asterisk-off"></Icon>
                <Icon name="star-fill" className="asterisk-on"></Icon>
              </div>
            </div>,
            [
              {
                dropdown: (
                  <DropdownItem tag={Link} to={routes.COUPON_EDIT(coupon.id)}>
                    <Icon name="edit"></Icon>
                    <span>Edit</span>
                  </DropdownItem>
                ),
                tooltip: (
                  <li className="nk-tb-action-hidden">
                    <TooltipComponent
                      tag={Link}
                      to={routes.COUPON_EDIT(coupon.id)}
                      containerClassName="btn btn-trigger btn-icon"
                      id={"edit" + coupon.id}
                      icon="edit"
                      direction="top"
                      text="Edit Coupon"
                    />
                  </li>
                ),
              },
              {
                dropdown: (
                  <DropdownItem
                    tag="a"
                    href="#"
                    onClick={() => onDeleteClick(coupon.id)}
                  >
                    <Icon name="trash-empty"></Icon>
                    <span>Delete</span>
                  </DropdownItem>
                ),
                tooltip: (
                  <li
                    className="nk-tb-action-hidden"
                    onClick={() => onDeleteClick(coupon.id)}
                  >
                    <TooltipComponent
                      tag="a"
                      containerClassName="btn btn-trigger btn-icon"
                      id={"delete" + coupon.id}
                      icon="trash-empty"
                      direction="top"
                      text="Delete Coupon"
                    />
                  </li>
                ),
              },
            ],
          ])}
          paginationData={paginationData}
          filterOptions={[
            {
              label: "Website",
              name: "website",
              options: [
                {
                  label: "All",
                  value: "",
                },
                ...websitesOption,
              ],
            },
          ]}
        />
      </Content>
    </>
  );
};

export default CouponsIndex;

