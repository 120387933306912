import React, { useState } from "react";

import Head from "../../layouts/dashboard/head/Head";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/routes-data";
import useRequest from "../../hooks/use-request";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-reducer";
import apiRoutes from "../../routes/api-routes";
import TwoWayAuth from "./partials/two-way-auth";
import LoginSection from "./partials/login-section";

const Login = () => {
  const [tab, setTab] = useState("login");

  const request = useRequest();
  const { sendRequest } = request;
  const [canRegister, setCanRegister] = useState(false);

  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAdmin = useCallback(async () => {
    const response = await sendRequest({
      url: apiRoutes.ADMIN_COUNT
    });
    if (response && response.admins_count === 0) {
      setCanRegister(true);
    } else {
      setCanRegister(false);
    }
  }, [sendRequest]);
  useEffect(() => {
    getAdmin();
  }, [getAdmin]);

  const onLoginCredentialVerified = async (response, inputs) => {
    if (response?.otp_sent) {
      setUserData({
        ...inputs,
        ...response
      });
      setTab("otp");
    } else {
      dispatch(authActions.onLoggedIn(response));
      navigate(routes.DASHBOARD_MAIN);
    }
  };

  const onOtpVerified = (response) => {
    dispatch(authActions.onLoggedIn(response));
    navigate(routes.DASHBOARD_MAIN);
  };

  return (
    <React.Fragment>
      <Head title="Login" />
      {tab === "login" && (
        <LoginSection
          request={request}
          canRegister={canRegister}
          onComplete={onLoginCredentialVerified}
        />
      )}

      {tab === "otp" && (
        <TwoWayAuth
          request={request}
          canRegister={canRegister}
          credentials={userData}
          onComplete={onOtpVerified}
        />
      )}
    </React.Fragment>
  );
};
export default Login;
